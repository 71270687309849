var fn = {};

$(function() {
    fn = {
        launch: function() {
            fn.app();
            fn.owl();
            fn.validateForms();
        },
        app: function() {

             $(".nav-mobile-menu-toggle .checkbox").on("change",function(){
                if($(this).is(":checked")){
                    $("ul.navbar-nav").slideDown();
                }else{
                    $("ul.navbar-nav").slideUp();
                }
            });

            if($("#contact-form").length > 0){
                $('#subject-select').on('change',function(){
                    if($(this).val() == 'other'){
                        $('#other-section').show();
                    }else{
                        $('#other-section').hide();
                    }
                });
            }
            if($('#donate-form').length > 0 ){
                $('#custom_amount').on('focus',function(){
                    $('#amount_other').prop('checked',true);
                     $('#custom_amount').addClass('required');
                });
                $('input[type="radio"]').bind('change', function(){
                    // Processing only those that match the name attribute of the currently clicked button...
                    $('input[name="' + $(this).attr('name') + '"]').not($(this)).trigger('deselect'); // Every member of the current radio group except the clicked one...
                });
                $('#amount_other').on('change',function(){
                    if($(this).is(':checked')){
                        $('#custom_amount').focus();
                        $('#custom_amount').addClass('required');
                    }
                        
                });
                $('#amount_other').on('deselect',function(){
                    $('#custom_amount').val('');
                    $('#custom_amount').removeClass('required');

                })
            }
        },
        owl:function(){
            if($(".news-slider").length > 0){
                $(".news-slider").owlCarousel({
                    items:4,
                    nav:true,
                    navText:["<i class='zmdi zmdi-chevron-left'></i>","<i class='zmdi zmdi-chevron-right'></i>"],
                    dots:false,
                    lazyLoad:true,
                    responsiveClass:true,
                    responsive:{
                        0:{
                            items:1

                        },
                        480:{
                            items:2
                        },
                        767:{
                            items:3

                        },
                        992:{
                            items:4

                        }
                    }
                });
            }

            function help(){
                if($(".home-help .round-content-wrap").length > 0){

                    if($(window).width() <= 800){
                        $(".round-content-wrap").owlCarousel({
                            items: 1,
                            itemElement:"a"
                        });
                    }
                }
            }

            help();


            window.onresize = help;
            $(window).trigger("resize");




        },
        submitSubscription:function(form){
            $('#subscription_btn').html('<img src="'+$(form).data('loader')+'"/>');
            $('#subscription_btn').attr('disabled','disabled');
            var ct = $('#subscription_btn').data('content');
            $.ajax({
                data: $(form).serialize(),
                url: $(form).attr('action'),
                type: 'post',
                dataType:'JSON',
                success: function(response) {
                    if (response) {
                        //var response = $.parseJSON(data);
                        if (response.status == 'success') {
                            if(response.redirect_url){
                                window.location.href = response.redirect_url;
                            }
                            $(form)[0].reset();
                            $(form).find('.is-success').each(function(){
                                $(this).removeClass('is-success');
                            });
                           
                        } else if (response.status == 'error') {
                            if(response.error_msg){
                                var elm = $(form).find('input[name=email]');
                                elm.removeClass('is-success').addClass('has-error');
                                elm.attr('data-original-title',"This email has already been subscribed!");
                                elm.tooltip();
                                elm.focus();
                            }else{
                                alert(response.message);
                            }
                        }
                    } //data close

                }, //success close
                complete: function(){
                    $('#subscription_btn').html("Subscribe");
                    $('#subscription_btn').removeAttr('disabled');
                }
            }); //ajax close
        },
        submitDonationForm: function(form){
            var _btn = $(form).find('button[type=submit]');
            _btn.attr('disabled','disabled');
            _btn.val('Requesting...');
            $.ajax({
                url:$(form).attr('action'),
                data:$(form).serialize(),
                type:'post',
                success: function(data){
                    if(data.status='success'){
                        $('#paypal_amount').val(data.price);
                        $('#return_url').val(data.route);
                        $('#custom-field').val(data.id);
                        $('#paypal-form').submit();
                    }else{

                    }
                },
                complete:function(){
                    _btn.val('Donate Now');
                    _btn.removeAttr('disabled');
                    grecaptcha.reset();
                    
                },
                error :function( jqXhr ) {
                    //process validation errors here.
                    $errors = jqXhr.responseJSON; //this will get the errors response data.
                    //show them somewhere in the markup

                    $.each( $errors, function( key, value ) {
                        console.log(key+" "+value);
                        var _inp = $(form).find('input[name='+key+']');
                        console.log(_inp);
                        var _errelm = _inp.siblings('label.error');
                        console.log(_errelm);
                        if(_errelm.length == 0){
                            console.log(';here');
                            var div = document.createElement('label');
                            div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
                            div.innerHTML = value;
                            _inp.after(div);
                        }else{
                            console.log('there is an elm');
                            _errelm.html(value);
                            _errelm.show();
                        }
                    });
                }
            });
        },
        validateForms: function(){
            //CONTACT FORM
            $('#contact-form').validate({
                ignore: ':hidden:not([name="g-recaptcha-response"])',
                rules: {
                    name: { required: true },
                    email: { required: true, email: true },
                    /*phone: { minlength: 7 },*/
                    subject: { required: true },
                    other: { required:true},
                    message: { required: true },
                    'g-recaptcha-response': { required: true }
                },
                messages: {
                    name: { required: 'Please enter your full name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    /*phone: { minlength: 'Phone number must be minimum of 7 digits.' },*/
                    subject: { required: 'Please select the subject of the email' },
                    other: { required:'Please enter the subject of the email' },
                    message: { required: 'Please enter the message of the email' },
                    'g-recaptcha-response': { required: 'Captcha is required' }
                },
                errorPlacement: function (error, element) {
                    if($(element).attr('id')=='g-recaptcha-response'){
                        $(element).parents('.form-group').append(error);
                    }else{
                        error.insertAfter(element);
                    }
                },
                highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                },
                submitHandler: function(form) {
                    form.submit();
                }
            });

            //SUBSCRIBE
            $('#subscribe-us').validate({
                rules: {
                    email: { required: true,email:true},
                },
                messages: {
                    email: { required: "Please enter your email address.", },
                },
                errorPlacement: function (error, element) {
                    $(element).attr('data-original-title',$(error).text());
                    $(element).tooltip();
                },
                success: function (label, element) {
                    $(element).attr('data-original-title','');
                    $(element).tooltip();
                },
                highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                },
                submitHandler: function(form) {
                    fn.submitSubscription(form);    
                }
            });

            $('#donate-form').validate({
                ignore: ':hidden:not([name="g-recaptcha-response"])',
                rules: {
                    name: { required: true },
                    email: { required: true, email: true },
                    /*phone: { minlength: 7 },*/
                    amount: { required: true },
                    message: { required: true },
                    custom_amount:{ min:5, number:true },
                    'g-recaptcha-response': { required: true }
                },
                messages: {
                    name: { required: 'Please enter your full name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    /*phone: { minlength: 'Phone number must be minimum of 7 digits.' },*/
                    subject: { required: 'Please select the subject of the email' },
                    amount: { required:'Please select the amount to donate.' },
                    custom_amount: { required:'Please enter the amount to donate.' },
                    message: { required: 'Please enter the message of the email' },
                    'g-recaptcha-response': { required: 'Captcha is required' }
                },
                errorPlacement: function (error, element) {
                    if($(element).attr('id')=='g-recaptcha-response' || $(element).attr('name')=='amount'){
                        $(element).parents('.form-group').append(error);
                    }else{
                        error.insertAfter(element);
                    }
                },
                highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                },
                submitHandler: function(form) {
                    fn.submitDonationForm(form);
                }
            });

            //SEARCH
            $('#search').validate({
                rules: {
                    keyword: { required: true},
                },
                messages: {
                    keyword: { required: "Please enter a keyword to search.", },
                },
                errorPlacement: function (error, element) {
                    $(element).attr('data-original-title',$(error).text());
                    $(element).tooltip();
                },
                success: function (label, element) {
                    $(element).attr('data-original-title','');
                    $(element).tooltip();
                },
                highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                }
            });
        }



    }

    $(document).ready(function() {
        fn.launch();
    });
});
